"use client";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	typography: {
		fontFamily: ["Kumbh Sans", "sans-serif"].join(","),
		fontSize: 12,
	},
	palette: {
		primary: {
			main: "#ffc000",
		},
		secondary: {
			main: "#878988",
			contrastText: "#dfdfdf",
		},
	},
});
